import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/qTnZKuIwd3Y">
    <SEO title="A Priest-King - Hebrews" />
  </Layout>
)
export default SermonPost
